import Home from './Home/Home';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
