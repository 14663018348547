import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./Home.css";

function Home() {
  const [toggleClass, setToggleClass] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  async function sendNotification(e) {
    e.preventDefault();
    const res = await fetch(
      "https://spireustech-e5017-default-rtdb.firebaseio.com/spireustech.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          subject,
          message,
        }),
      }
    );
    if (res) {
      toast.success("Message sent successfully");
    } else {
      toast.warn("Something went wrong!");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector("#header");
    const scrollTop = window.scrollY;
    if (scrollTop >= 50) {
      header && header.classList.add("sticky");
    } else {
      header && header.classList.remove("sticky");
    }
  };

  return (
    <>
      <ToastContainer position="bottom-left" />
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <div className="logo me-auto">
            <a href="/">
              <img
                style={{ height: "50px" }}
                src="/assets/img/CompanyLogo.png"
                alt="logo"
              />
            </a>
          </div>

          <nav id="navbar" className={toggleClass ? "navbar-mobile" : "navbar"}>
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#faq">
                  Career
                </a>
              </li>

              <li>
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <div onClick={() => setToggleClass(!toggleClass)}>
              {toggleClass ? (
                <i
                  onClick={() => setToggleClass(!toggleClass)}
                  className="bi bi-x mobile-nav-toggle"
                ></i>
              ) : (
                <i
                  onClick={() => setToggleClass(!toggleClass)}
                  className="bi bi-list mobile-nav-toggle"
                ></i>
              )}
            </div>
          </nav>
        </div>
      </header>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="
              col-lg-6
              d-flex
              flex-column
              justify-content-center
              pt-4 pt-lg-0
              order-2 order-lg-1
            "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Better Solutions For Your Business</h1>
              <h2>We are team of talented Designers And Developer.</h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#contact" className="btn-get-started scrollto">
                  Contact Us
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="/assets/img/hero-img.png"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About Us</h2>
            </div>

            <div className="row content">
              <div className="col-lg-6">
                <p>
                  We campaign team of synergy from individual and set up the
                  business with change + positive progressive frame of mind in
                  every aspect of work line. We satisfied our clients with this
                  approach and gain unbelievable success within short period of
                  time.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> Thanks to our
                    reporting you're always in control of your budget. Easily
                    follow costs for development or administration.
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> The success of your
                    product is our success as well. You can count on our
                    recommendations and advice from day one.
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> Working on the
                    hour, we value your time and will deliver finished product
                    that meets quality coding standards and deadlines.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  We deliver extraordinary and easily operated user-friendly
                  Mobile app and web development services to our clients. Having
                  a prominent team of developers and their expertise to mobilize
                  the latest technological updates, we have been able to serve
                  leading industries like healthcare, retail, education,
                  hospitality service, and many more. With our innovative
                  approach and dedication to quality, We provide excellent
                  services that offer a competitive edge to businesses And grow
                  up your business.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="
                col-lg-7
                d-flex
                flex-column
                justify-content-center
                align-items-stretch
                order-2 order-lg-1
              "
              >
                <div className="content">
                  <h3>
                    <strong>Who We Are? </strong>
                  </h3>
                  <p>
                    SpireUs work with passion to bring ideas to life and
                    create stunning applications for start-ups and corporate
                    clients. We mainly focus on SaaS based application,
                    eCommerce Application, Mobile app development, Web
                    application development, chatbots.
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        href="/"
                        data-bs-toggle="collapse"
                        className="collapse"
                        data-bs-target="#accordion-list-1"
                      >
                        <span>01</span> Vision
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse show"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          We Make Businesses Successful by Transforming Ideas
                          into Reality. And help to brings our client's business
                          beyond the next level IT Solutions.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        href="/"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>02</span> Mission
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          Satisfied clients always matter to us while providing
                          fast and curious services with quality of works. As we
                          care for the client's requirement exactly transforming
                          with the actual match.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        href="/"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>03</span> Commitment
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-3"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          Supportive Team of developers is always ready to
                          provide the fastest and curious transparent business
                          solution to clients with much hike support and help
                          them to claim growth own business.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                style={{ backgroundImage: "url('assets/img/why-us.png')" }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                &nbsp;
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Services</h2>
              <p>
                The great news is, learning to become a developer is easily
                accessible and affordable. This is especially true with
                OpenClassrooms. Emily Reese, Web developer and Teacher at
                OpenClassrooms explains,
              </p>
            </div>

            <div className="row">
              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4>
                    <a href="/">On-demand app development:</a>
                  </h4>
                  <p>
                    Mobile technologies have become an important platform to
                    conduct business, generate leads and support operations.
                    Mobile platforms are changing very rapidly, as new devices,
                    uses and operating systems have been introduced by the
                    market
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <h4>
                    <a href="/">Hire dedicated developer:</a>
                  </h4>
                  <p>
                    One of the major advantages of hiring a dedicated
                    development team is it is economically reasonable. It costs
                    much less for a dedicated team than to hire your own team to
                    work on a project.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-tachometer"></i>
                  </div>
                  <h4>
                    <a href="/">Web Design:</a>
                  </h4>
                  <p>
                    A web designer works on the appearance, layout, and, in some
                    cases, content of a website. Appearance, for instance,
                    relates to the colors, font, and images used.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="400"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-layer"></i>
                  </div>
                  <h4>
                    <a href="/">Web Development:</a>
                  </h4>
                  <p>
                    Web development skills are in high demand worldwide and well
                    paid too – making development a great career option. It is
                    one of the easiest accessible higher paid fields as you do
                    not need a traditional university degree to become
                    qualified.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-start">
                <h3>Contact Us</h3>
                <p>
                  Join a team of passionate technology experts who create
                  progress every day, and take care of each other at every step
                  of the professional journey.
                </p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <a className="cta-btn align-middle" href="#contact">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Current Opening</h2>
              <p>What's your expertise? See where do you fit in.</p>
            </div>

            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bxl-nodejs icon-help"></i>
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-1"
                    className="collapsed"
                  >
                    Node Js <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ul className="subUl">
                      <li className="subLi">Knowledge of Node.js frameworks</li>
                      <li className="subLi">
                        {" "}
                        Understanding asynchronous programming.
                      </li>
                      <li className="subLi">Multiple database approaches.</li>
                      <li className="subLi">
                        Multiple delivery platforms, such as mobile vs. desktop
                      </li>
                      <li className="subLi">Server-side templating</li>
                    </ul>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bxl-react icon-help"></i>
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    React Js <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ul className="subUl">
                      <li className="subLi">
                        Knowledge of React.js frameworks
                      </li>
                      <li className="subLi">Understanding LifeCycle Method</li>
                      <li className="subLi">How does React use Virtual DOM</li>
                      <li className="subLi">
                        Understanding ReactJS — Components
                      </li>
                      <li className="subLi">How React-Redux Work</li>
                    </ul>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bxl-vuejs icon-help"></i>
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    Vue.js <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ul className="subUl">
                      <li className="subLi">Knowledge of Vue.js frameworks</li>
                      <li className="subLi">
                        Understanding LifeCycle Components
                      </li>
                      <li className="subLi">How does Vue use Virtual DOM</li>
                      <li className="subLi">
                        Understanding VueJS — Components
                      </li>
                      <li className="subLi">How VueX Work</li>
                    </ul>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-server icon-help"></i>
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                  >
                    Devops Engineer
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ul className="subUl">
                      <li className="subLi">
                        Knowledge of Automatic deployment
                      </li>
                      <li className="subLi">Understanding CI/CD pipeline</li>
                      <li className="subLi">
                        Understanding Git,Docker,Jenkins,Kubernetes
                      </li>
                      <li className="subLi">
                        How Does AWS infrastructure work
                      </li>
                    </ul>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-bot icon-help"></i>
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                  >
                    Automated Testing
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ul className="subUl">
                      <li className="subLi">
                        Knowledge of Automatic Testing In Web And Mobile
                        Technologies
                      </li>
                      <li className="subLi">
                        Understanding Jest,SuperTest And cypress In Web
                        Technologie
                      </li>
                      <li className="subLi">
                        Understanding of Appium In mobile Technologie
                      </li>
                      <li className="subLi">How Does E2E Test Works</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>
                Email Us With Any Questions or inquire or call +91 7048568494.
                We would be happy to answer your Questions and set up a meeting
                with you.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      408, Avadh Viceroy, Sarthana Jakat Naka-Kamrej Rd, opp.
                      Rajhans Swapna, Sarthana Jakat Naka, Subhash Nagar, Surat,
                      Gujarat 395006
                    </p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>hr@spireustech.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+91 7048568494</p>
                  </div>

                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2102.366455249525!2d72.90240888579923!3d21.230211867977733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be045f4b61ead47%3A0x8b235392ebd116f4!2sAvadh%20Viceroy!5e0!3m2!1sen!2sin!4v1643277426210!5m2!1sen!2sin"
                    style={{ border: 0, width: "100%", height: 290 }}
                    title="google"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form
                  onSubmit={(e) => sendNotification(e)}
                  method="POST"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="name">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={(e) => setUsername(e.target.value)}
                        id="name"
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="name">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="name">Subject</label>
                    <input
                      type="text"
                      onChange={(e) => setSubject(e.target.value)}
                      className="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label for="name">Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                      rows="10"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <h3 className="logo me-auto">
                  <img
                    style={{ height: "80px" }}
                    src="/assets/img/Capture.png"
                    alt="logo"
                  />
                </h3>
                <address>
                  <strong> Address:</strong> 408, Avadh Viceroy, Sarthana Jakat
                  Naka-Kamrej Rd, opp. Rajhans Swapna, Sarthana Jakat Naka,
                  Subhash Nagar, Surat, Gujarat 395006
                  <br />
                  <strong> Phone:</strong> +91 7048568494
                  <br />
                  <strong>Email:</strong> hr@spireustech.com
                  <br />
                </address>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/">Web Design</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/">Web Development</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-5 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>follow Our social Networks</p>
                <div className="social-links mt-3">
                  <a href="/#" className="twitter">
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a href="/#" title="" className="facebook">
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/spireus_technology/"
                    target="_blank"
                    rel="noreferrer"
                    className="instagram"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/spireus-technology/"
                    target="_blank"
                    rel="noreferrer"
                    className="linkedin"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                  <a href="/#" className="linkedin">
                    <i className="bx bxl-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright
            <strong>
              <span> SpireUs</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>

      {/* <div id="preloader"></div> */}
      <a
        href="/"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default Home;
